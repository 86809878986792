.number input {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number[type=number] {
  -moz-appearance: textfield;
}