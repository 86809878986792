.table .ant-table-row:hover {
  cursor: pointer;
}

.filters {
  display: flex;
}

.filter {
  margin-right: 15px;
}

.siteLB {
  background: #fff !important;
}

.center {
  display: flex;
  justify-content: center;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.paretoList {
  list-style-type: decimal;
}
