.textNode {
  padding: 10px;
  height: 100%;
}

.textNode textarea {
  height: 100% !important;
}

.node .handle {
  opacity: 0;
}

.node:hover .handle {
  opacity: 1;
}

.wrapperNode {
  height: 100%;
}

.wrapperNode .resizeLine, .wrapperNode .resizeHandle {
  opacity: 0;
}

.wrapperNode:hover .resizeLine,
.wrapperNode:hover .resizeHandle  {
  opacity: 1;
}

.show {
  opacity: 1 !important;
}

.pointHandle {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}