.labelWrapper:hover button {
  opacity: 1;
}

.box {
  border: 1px solid #999;
  font-size: 9px;
  background-color: #fff;
  border-radius: 3px;
}

.option {
  font-size: 15px;
}

.pointEdgePath + circle + circle {
  /* display: none; */
}

.actions {
  display: flex;
  align-items: center;
}

.actions > *:first-child {
  margin-right: 4px;
}