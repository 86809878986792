.side {
  width: 300px;
  border: 1px solid black;
  position: absolute;
  background-color: #fff;
  z-index: 100;
}

.menu_item {
  cursor: pointer;
  background-color: transparent;
  display: flex !important;
  margin-bottom: 0px !important;
  height: 50px;
  align-items: center !important;
  justify-content: center !important;
}

.item :global(.ant-list-item-meta-title) {
  display: flex;
  justify-content: center;
}