.wrapper {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  position: relative;
}

.download :global(.ant-typography) {
  margin-bottom: 0;
  word-break: keep-all;
  text-align: center;
}

.saveButton {
  position: absolute;
  right: 15px;
}