.table table {
  height: 1px;
}

.controls {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.diagonalLine {
  background-image: linear-gradient(to top right, transparent 49.5%, #000 50%, transparent 50.5%);
  background-repeat: no-repeat;
  height: 100%;
}

.row {
  height: 100%;
}

.textLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.textRight {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.verticalText {
  transform: rotate(270deg);
  margin-bottom: 0 !important;
  word-break: keep-all !important;
}

.verticalText:global(.ant-typography-edit-content) {
  transform: none;
}
