.tag {
  border-radius: 15px !important;
  font-size: 16px !important;
  padding: 5px 9px !important;
  min-width: 62px;
  text-align: center;
}

.table .ant-table-row:hover {
  cursor: pointer;
}
