.fishboneChart {
  display: flex;
  justify-items: center;
  min-height: 200px;
}

.causes {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.causesGroup {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.cause {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  font-size: 0.95em;
  font-weight: 400;
  color: #4f4f4f;
}
.causeFirstLine {
  display: flex;
  align-items: center;
}
.causeAndLine {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
}
.rootCauses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.85em;
  font-weight: 400;
  color: #4f4f4f;
  text-align: right;
  padding: 7px 0px;
}
.blue_ {
  background: rgb(0, 192, 239, 0.1);
}
.blue_two_ {
  background: rgb(11, 120, 206, 0.1);
}
.blue_dark {
  background: rgb(0, 192, 239, 0.4);
}
.blue_two_dark {
  background: rgb(11, 120, 206, 0.4);
}
.blue_twoBorder {
  border-color: rgb(11, 120, 206) !important;
}
.labelSquare {
  flex: 1;
  width: 42px;
  height: 14px;
  margin: 0px 4px 0px 4px;
}
.labelLineThrough {
  text-decoration: line-through;
  font-size: 0.95em;
  font-weight: 400;
  color: #4f4f4f;
}
.label_ {
  font-size: 0.95em;
  font-weight: 400;
  color: #4f4f4f;
}
.effect {
  display: flex;
  justify-content: center;
  align-items: center;
}
.diagonalLine {
  width: 10%;
  height: 100%;
}
.blueTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(0, 192, 239),
    transparent calc(50% + 1.5px)
  );
}
.blueBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(0, 192, 239),
    transparent calc(50% + 1.5px)
  );
}
.blue_twoTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(11, 120, 206),
    transparent calc(50% + 1.5px)
  );
}
.blue_twoBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(11, 120, 206),
    transparent calc(50% + 1.5px)
  );
}
.lineEffect {
  width: 100%;
  border-bottom: 2px solid;
}
.causeContent {
  display: flex;
  flex-direction: column;
  min-height: 50%;
  margin-right: 15px;
}
